<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility6"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">App Settings</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility6 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="">
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Teacher App</h4>
            <b-form-checkbox v-model="appObj.teacherApp" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Father App</h4>
            <b-form-checkbox v-model="appObj.fatherApp" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Mother App</h4>
            <b-form-checkbox v-model="appObj.motherApp" switch />
          </b-col>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <h4 class="mr-1">Student App</h4>
            <b-form-checkbox v-model="appObj.studentApp" switch />
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              variant="success"
              :disabled="saving"
              block
              @click="saveApp()"
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row align-h="center">
          <!-- <b-col cols="4"> </b-col> -->
          <b-col class="ml-3" cols="4">
            <b-form-group
              class="text-center"
              ref="picture"
              :state="nameState"
              label=" "
              label-for="name-input"
              invalid-feedback="Picture is required."
            >
              <b-media no-body ref="picture">
                <div v-if="logoloading == true">
                  <b-spinner
                    variant="primary"
                    type="grow"
                    class="m-5"
                    label="Spinning"
                  ></b-spinner>
                </div>

                <b-media-aside>
                  <b-link>
                    <b-img
                      ref="previewEl"
                      src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                      @click="$refs.fileProfile.click()"
                      v-if="logoloading == false"
                      style="border-radius: 100px"
                      fluid
                      height="120px"
                      width="120px"
                    />
                  </b-link>

                  <b-link v-if="logoloading == 'loaded'">
                    <div class="text-right" style="margin-right: 35px">
                      <b-button
                        @click="deleteLogo()"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="position-absolute btn-icon"
                        variant="danger"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>

                    <b-img
                      class="rcorners2"
                      :src="plus"
                      height="120px"
                      width="120px"
                      style="border-radius: 50%"
                    />

                    <br />
                  </b-link>
                  <input
                    type="file"
                    id="fileProfile"
                    hidden
                    ref="fileProfile"
                    @change="selectlogo()"
                    accept="image/*"
                  />
                </b-media-aside>
              </b-media>
              <small class="mr-1 text-danger" v-if="logoerror"
                >Logo is required.</small
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group label="Name" invalid-feedback="name is required.">
              <b-form-input
                id="mc-first-name"
                placeholder="Enter name"
                name="classField"
                ref="nameUser"
                v-model="userObj.name"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Username"
              invalid-feedback="username is required."
              ref="UName"
            >
              <b-form-input
                id="mc-first-name"
                placeholder="Enter username"
                name="classField"
                ref="uName"
                :disabled="userObj.id > 0"
                v-model="userObj.username"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Password"
              invalid-feedback="password is required."
            >
              <b-form-input
                id="mc-first-name"
                type="password"
                placeholder="Enter password"
                name="classField"
                ref="pass"
                v-model="userObj.password"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Select role"
              invalid-feedback="role is required."
              ref="role"
            >
              <v-select
                ref="role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                v-model="selectedRole"
                :options="allRoles"
                label="name"
                placeholder="select role"
                @input="setRoleData()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="loading"
              block
            >
              <b-spinner v-if="loading" small type="grow" />
              <span v-if="loading == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>
    <b-modal
      id="modal-roles"
      hide-footer
      hide-header
      centered
      title="Settings"
      size="lg"
    >
      <div class="p-1">
        <h1 class="text-center">Add New Role</h1>
        <h5 class="text-center">Set role permissions</h5>

        <b-row class="ml-2 mr-2 mt-1">
          <b-col class="p-0" md="12">
            <b-form-group label="Role Name ">
              <b-form-input
                ref="name"
                v-model.trim="roleObj.name"
                placeholder="Enter role name"
                @focusout="CheckName()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="scrollContent">
          <b-row class="m-1 mb-1">
            <b-col class="p-0" md="5">
              <!-- <h5 class="mb-0 d-flex justify-content-end">Select All</h5> -->
            </b-col>
            <b-col class="d-flex align-items-center flex-wrap" md="7">
              <b-form-checkbox
                @change="ToggleAll('view')"
                v-model="all.view"
              /><span class="mr-1">View</span>
              <b-form-checkbox
                @change="ToggleAll('add')"
                v-model="all.add"
              /><span class="mr-1">Add</span>
              <b-form-checkbox
                @change="ToggleAll('edit')"
                v-model="all.edit"
              /><span class="mr-1">Edit</span>
              <b-form-checkbox
                @change="ToggleAll('delete')"
                v-model="all.delete"
              /><span class="mr-1">Delete</span>
            </b-col>
          </b-row>

          <b-row
            class="m-1 mb-1"
            v-for="item in myObj"
            :key="item.id"
            v-if="item.children.length > 0"
          >
            <b-col class="p-0" md="12">
              <h4 class="font-weight-bolder mb-0">{{ item.name }}</h4>
            </b-col>
            <b-col v-for="child in item.children" :key="child" md="12">
              <b-row
                style="border-bottom: 1px solid lightgray"
                class="pt-1 pb-1"
              >
                <b-col class="p-0" md="5">
                  <h5 class="mb-0">{{ child.title }}</h5>
                </b-col>
                <b-col class="d-flex align-items-center flex-wrap" md="7">
                  <b-form-checkbox v-model="child.view"
                    ><span class="mr-1">View</span>
                  </b-form-checkbox>

                  <b-form-checkbox v-model="child.add">
                    <span class="mr-1">Add</span>
                  </b-form-checkbox>

                  <b-form-checkbox v-model="child.edit"
                    ><span class="mr-1">Edit</span>
                  </b-form-checkbox>

                  <b-form-checkbox v-model="child.delete"
                    ><span class="mr-1">Delete</span>
                  </b-form-checkbox>

                  <template v-if="child.title == 'Students'">
                    <b-form-checkbox v-model="child.hideFee"
                      ><span class="mr-1">Hide Fee</span>
                    </b-form-checkbox>
                    <b-form-checkbox v-model="child.editCampus"
                      ><span class="mr-1">Edit Campus</span>
                    </b-form-checkbox>

                    <!-- <template v-if="reportDomain == 'myskoolhpgs'">
                      <b-form-checkbox v-model="child.confirmAdmission"
                        ><span class="mr-1">Confirm Admission</span>
                      </b-form-checkbox>
                      <b-form-checkbox v-model="child.leftStudent"
                        ><span class="mr-1">Left Student</span>
                      </b-form-checkbox>
                    </template> -->
                  </template>

                  <template
                    v-if="
                      reportDomain == 'myskoolhpgs' &&
                      ['Staff Applicants', 'Applicants'].includes(child.title)
                    "
                  >
                    <b-form-checkbox v-model="child.interviewInfo"
                      ><span class="mr-1">Interview Information</span>
                    </b-form-checkbox>
                    <b-form-checkbox v-model="child.approve"
                      ><span class="mr-1">Approve Applicant</span>
                    </b-form-checkbox>
                  </template>

                  <b-form-checkbox
                    v-if="child.route == 'requisition'"
                    v-model="child.approve"
                    ><span class="mr-1">Approve</span>
                  </b-form-checkbox>

                  <template v-if="child.route == 'manage-challan'">
                    <b-form-checkbox v-model="child.receiveFee"
                      ><span class="mr-1">Receive Fee</span>
                    </b-form-checkbox>
                    <b-form-checkbox v-model="child.disableDate"
                      ><span class="mr-1">Disable Date</span>
                    </b-form-checkbox>
                  </template>

                  <template v-if="child.route == 'report'">
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="child.reportTypes"
                      :options="reportOptions"
                    ></b-form-checkbox-group>
                  </template>

                  <b-form-checkbox
                    v-if="importList.includes(child.route)"
                    v-model="child.disableImport"
                    ><span class="mr-1">Hide Import</span>
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-if="exportList.includes(child.route)"
                    v-model="child.disableExport"
                    ><span class="mr-1">Hide Export</span>
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-if="child.title == 'Campuses'"
                    v-model="child.permission"
                    value="switch-campus"
                    unchecked-value=""
                    ><span class="mr-1">Switch Campus</span>
                  </b-form-checkbox>

                  <!-- <b-form-checkbox
                    v-for="data in child.permission"
                    :key="data"
                    v-model="data.value"
                    class="mr-1"
                    >{{ data.text }}
                  </b-form-checkbox> -->
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <b-row class="mt-1">
          <b-col md="12" class="text-right">
            <b-button variant="success" @click="save()" :disabled="request">
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      id="modal-classes"
      hide-footer
      centered
      title="Manage Classes"
      size="lg"
    >
      <b-row class="mb-1">
        <b-col md="12">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="currentUser.avatar"
                variant="light-primary"
              />
            </template>

            <b-link class="font-weight-bold d-block text-nowrap">
              {{ currentUser.name }}
            </b-link>
            <small class="text-muted">{{ currentUser.username }}</small>
          </b-media>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Class"
            invalid-feedback="Class is required."
            ref="tcID"
          >
            <v-select
              v-model="teachingClassObj.clsID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="classesOptions"
              :clearable="false"
              :reduce="(val) => val.id"
              label="name"
              input-id="id"
              ref="tcID"
              @input="FillSections()"
              @search:blur="CheckTcID()"
              :disabled="dataloading"
              placeholder="Select class"
            />
          </b-form-group>
        </b-col>
        <b-col md="8">
          <b-form-group
            label="Section"
            invalid-feedback="Section is required."
            ref="tSection"
          >
            <v-select
              ref="tSection"
              multiple
              :closeOnSelect="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="selectedSections"
              :options="admittedSections"
              :reduce="(val) => val.id"
              label="section"
              :clearable="false"
              :disabled="dataloading"
              @input="setSections()"
              @search:blur="CheckTSection()"
              placeholder="Select section"
            />
          </b-form-group>
        </b-col>
        <b-col md="10">
          <b-form-group
            label="Subject"
            invalid-feedback="Subject is required."
            ref="tSubjects"
          >
            <v-select
              ref="tSubjects"
              multiple
              :closeOnSelect="false"
              v-model="selectedSubs"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subjects"
              :reduce="(val) => val.id"
              label="subject"
              :clearable="false"
              :disabled="dataloading"
              @input="setSubjects()"
              @search:blur="CheckTSubject()"
              placeholder="Select assigned subject."
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group label="" class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-icon"
              variant="success"
              @click="assign()"
              :disabled="assigning"
              block
            >
              <b-spinner v-if="assigning" small />
              <span v-else>Save</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        class="mt-1"
        sticky-header
        :items="gridData"
        :fields="fields2"
        responsive
        hover
      >
        <template #cell(subjects)="data">
          <b-badge
            variant="light-primary"
            v-for="sub in data.item.subjects"
            :key="data.sub"
            style="margin: 2px"
          >
            <span>{{ sub.subject }}</span>
            <feather-icon
              icon="XIcon"
              class="ml-50"
              @click="removeSingle(data.item, sub)"
            />
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="DeleteSub(data.item)"
              v-b-tooltip.hover.left
              title="Delete"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-modal>

    <div
      v-if="dataLoading"
      class="d-flex justify-content-center mb-3"
      style="margin-top: 50px"
    >
      <b-spinner
        style="width: 3rem; height: 3rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </div>
    <b-row class="match-height" v-else>
      <b-col md="3" v-for="role in allRoles" :key="role.id">
        <b-card>
          <div class="d-flex justify-content-between">
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">{{ role.name }}</h4>
              <b-spinner
                v-if="editLoading && checkId == role.id"
                small
                variant="primary"
              />
              <span
                v-else-if="rights.edit"
                class="cursor-pointer text-primary"
                @click="openEdit(role)"
                >Edit Role</span
              >
            </div>
            <b-avatar class="mb-1" variant="light-primary" size="45" />
          </div>
        </b-card>
      </b-col>
      <b-col md="3" v-if="rights.add">
        <b-card>
          <div class="d-flex justify-content-between">
            <b-avatar class="mb-1" variant="light-primary" size="45" />
            <div class="text-right">
              <b-button
                variant="primary"
                @click="openModal()"
                :disabled="request"
              >
                <b-spinner v-if="request" small />
                <span class="text-nowrap" v-else>Add Role </span>
              </b-button>
              <p class="mt-1 mb-0">Add role if it doesn't exist</p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-start">
              <b-button
                v-if="rights.add"
                variant="primary"
                class="mr-1"
                @click="AddOpen()"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Search..."
              />
              <b-button
                v-if="rights.edit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.top
                title="App Settings"
                class="btn-icon ml-1"
                @click="visibility6 = true"
              >
                <feather-icon icon="SettingsIcon" class="" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="filteredUser"
        responsive
        :fields="fields"
        :busy="userLoading"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                variant="light-primary"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <!-- <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
            {{ data.item.name }}
          </b-link> -->
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(meta_role)="data">
          <b-badge pill variant="light-primary" class="text-capitalize">
            {{ data.item.meta_role }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="rights.edit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-tooltip.hover.left
            title="Edit"
            class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
            @click="Edit(data.item)"
          >
            <feather-icon icon="EditIcon" class="" />
          </b-button>

          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            v-b-tooltip.hover.left
            title="Manage Classes"
            class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
            @click="openClasses(data.item)"
          >
            <feather-icon icon="BookIcon" class="" />
          </b-button> -->

          <b-button
            v-if="rights.delete"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            v-b-tooltip.hover.right
            title="Delete"
            class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
            @click="Delete(data.item.id)"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown> -->
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ filteredUser.length }} of
              {{ allUsers.length }} entries</span
            >
            <!-- <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            > -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BMediaAside,
  BImg,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BSidebar,
  BFormCheckbox,
  BFormCheckboxGroup,
  BSpinner,
  BForm,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import userStoreModule from "../userStoreModule";
import UserListAddNew from "./UserListAddNew.vue";
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    // VBTooltip,
    UsersListFilters,
    UserListAddNew,
    BFormCheckbox,
    BFormCheckboxGroup,
    BModal,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMediaAside,
    BImg,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BSidebar,
    BDropdownItem,
    BPagination,
    BSpinner,

    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  computed: {
    filteredUser() {
      return this.allUsers.filter((pro) => {
        return (
          pro.username.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          (pro.name &&
            pro.name.toLowerCase().match(this.searchQuery.toLowerCase()))
        );
      });
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const isAddNewUserSidebarActive = ref(false);

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      // searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      // searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    };
  },
  data() {
    return {
      searchQuery: "",
      all: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      fileProfile: "",
      logoloading: false,
      plus: "",
      options: [
        { text: "View", value: "view" },
        { text: "Add", value: "add" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      myObj: [],
      allRoles: [],
      allUsers: [],
      fields: [
        { label: "user", key: "user" },
        { label: "username", key: "username" },
        { label: "role", key: "meta_role" },
        { key: "actions", label: "actions" },
      ],
      roleObj: {
        id: 0,
        campusID: 0,
        name: "",
        forms: "",
      },
      userObj: {
        id: 0,
        username: "",
        password: "",
        userRole: 0,
        name: "",
        avatar: "",
        forms: "",
        meta_role: "",
      },
      selectedRole: null,
      request: false,
      loading: false,
      userLoading: false,
      visibility: false,
      dataLoading: false,
      editLoading: false,
      checkId: 0,
      rights: {},
      permList: [
        {
          title: "Students",
          data: [
            { text: "Hide Export", value: false },
            { text: "Hide Contacts", value: false },
          ],
        },
        {
          title: "Staff",
          data: [
            { text: "Hide Export", value: false },
            { text: "Hide Contacts", value: false },
          ],
        },
      ],
      classes: [],
      classesOptions: [],
      admittedSections: [],
      subjects: [],
      currentUser: {},
      gridData: [],
      fields2: [
        { label: "Class", key: "cls" },
        { label: "section", key: "sec" },
        { label: "subject", key: "subjects" },
        "actions",
      ],
      assigning: false,
      teachingClassObj: {
        userID: 0,
        clsID: 0,
        sections: [],
        subjects: [],
        campusID: this.$store.state.userData.cId,
      },
      selectedSubs: [],
      selectedSections: [],
      appObj: {},
      visibility6: false,
      saving: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      importList: [
        "manage-studentgrid",
        "student-coachinggrid",
        "manage-staffgrid",
      ],

      exportList: [
        "manage-inquiry",
        "inquiry",
        "manage-studentgrid",
        "student-coachinggrid",
        "manage-staffgrid",
        // "manage-datesheet",
        // "manage-marks"
      ],
      reportOptions: [
        { text: "Collection Report", value: "dcr" },
        { text: "Outstanding Report", value: "defaulters" },
        { text: "Students", value: "Students" },
        { text: "Staff", value: "Staff" },
        { text: "Attendance", value: "Attendance" },
        { text: "Accounts", value: "Accounts" },
        { text: "Advance Accounts", value: "Advance Accounts" },
        { text: "Certificates", value: "Certificates" },
      ],
    };
  },
  created() {
    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);
      this.LoadData();
      this.LoadUsers();
      // this.LoadInfo();

      this.LoadSettings();
    }
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    // ===== app settings ========

    async LoadSettings() {
      var obj = {
        url:
          this.$store.state.domain +
          "AppSettings?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      this.appObj = res.find(
        (el) => el.campusID == this.$store.state.userData.cId
      );
      if (!this.appObj) {
        this.appObj = {
          id: 0,
          campusID: this.$store.state.userData.cId,
          teacherApp: false,
          fatherApp: false,
          motherApp: false,
          studentApp: false,
        };
      }
      // console.log(this.appObj);
    },
    async saveApp() {
      this.saving = true;
      if (this.appObj.id == 0) {
        let status = await this.post({
          url:
            this.$store.state.domain +
            "AppSettings?db=" +
            this.$store.state.userData.db,
          body: this.appObj,
          message: "Settings saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadSettings();
          this.visibility6 = false;
        }
      } else {
        let status = await this.put({
          url:
            this.$store.state.domain +
            "AppSettings/" +
            this.appObj.id +
            "?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.appObj,
          message: "Settings saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadSettings();
          this.visibility6 = false;
        }
      }
      this.saving = false;
    },

    // ====== assign classes =======

    async LoadInfo() {
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classes.forEach((el) => {
        this.classesOptions.push(el.cls);
      });

      var obj2 = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj2);
      if (this.subjects.length > 0) {
        this.subjects.unshift({ subject: "All Subjects", id: 0 });
      }
    },
    openClasses(item) {
      this.currentUser = item;
      this.$bvModal.show("modal-classes");

      // this.loadManageData();
    },
    async loadManageData() {
      var obj = {
        url:
          this.$store.state.domain +
          "TeacherClasses/LoadDataWebNew?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          this.currentUser.id,
        token: this.$store.state.userData.token,
      };
      this.gridData = await this.get(obj);
    },
    FillSections(text) {
      let obj = this.classes.find(
        (el) => el.cls.id == this.teachingClassObj.clsID
      );
      if (obj.sections.length > 0) {
        this.admittedSections = [...obj.sections];
        this.admittedSections.unshift({ section: "All Sections", id: 0 });
      } else {
        this.admittedSections = [];
      }

      if (text != "filled") this.selectedSections = [];
      // console.log("sec", this.admittedSections);
    },
    setSections() {
      if (this.selectedSections.at(-1) == 0) {
        this.selectedSections = [0];
      } else {
        this.selectedSections = this.selectedSections.filter((el) => el != 0);
      }
      // console.log(this.selectedSections);
    },
    setSubjects() {
      if (this.selectedSubs.at(-1) == 0) {
        this.selectedSubs = [0];
      } else {
        this.selectedSubs = this.selectedSubs.filter((el) => el != 0);
      }
      // console.log(this.selectedSubs);
    },
    CheckTcID() {
      var elem = this.$refs["tcID"];
      if (this.teachingClassObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSection() {
      var elem = this.$refs["tSection"];
      if (this.selectedSections.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSubject() {
      var elem = this.$refs["tSubjects"];
      if (this.selectedSubs.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async assign() {
      this.teachingClassObj.userID = parseInt(this.currentUser.id);
      // console.log(this.teachingClassObj);

      this.CheckTcID();
      this.CheckTSubject();
      this.CheckTSection();
      if (
        this.CheckTcID() == false ||
        this.CheckTSubject() == false ||
        this.CheckTSection() == false
      ) {
        return this.$bvToast.toast("Please enter all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        this.assigning = true;

        if (this.selectedSections[0] == 0) {
          let secArr = this.classes.find(
            (el) => el.cls.id === this.teachingClassObj.clsID
          ).sections;
          let secs = secArr.reduce((acc, el) => {
            acc.push(el.id);
            return acc;
          }, []);
          this.teachingClassObj.sections = secs;
        } else {
          this.teachingClassObj.sections = this.selectedSections;
        }
        // console.log(this.teachingClassObj.sections);

        if (this.selectedSubs[0] == 0) {
          let subs = this.subjects.reduce((acc, el) => {
            if (el.id != 0) acc.push(el.id);
            return acc;
          }, []);
          this.teachingClassObj.subjects = subs;
        } else {
          this.teachingClassObj.subjects = this.selectedSubs;
        }
        // console.log(this.teachingClassObj.subjects);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/SaveData?db=" +
            this.$store.state.userData.db,
          body: this.teachingClassObj,
          message: "Classes assigned successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.loadManageData();
          this.teachingClassObj = {
            userID: this.currentUser.id,
            clsID: 0,
            sections: [],
            subjects: [],
            campusID: this.$store.state.userData.cId,
          };
          this.selectedSections = [];
          this.selectedSubs = [];
        }
        this.assigning = false;
      }
    },
    async removeSingle(row, sub) {
      // console.log(row, sub);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "TeacherClasses/" +
            sub.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "subject removed successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadManageData();
      }
    },
    async DeleteSub(item) {
      // console.log(item);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Staff/DeleteSubjects?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&clsID=" +
            item.clsID +
            "&secID=" +
            item.secID +
            "&tID=" +
            this.currentUser.id,
          body: null,
          message: "subject removed successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadManageData();
      }
    },
    // ====== assign classes =======

    async LoadData() {
      this.dataLoading = true;
      var obj2 = {
        url:
          this.$store.state.domain +
          "Roles?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allRoles = await this.get(obj2);
      // console.log(this.allRoles);

      this.dataLoading = false;
    },
    async LoadUsers() {
      this.userLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Users/LoadUsers?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allUsers = await this.get(obj);
      // console.log(this.allUsers);
      this.userLoading = false;
    },
    ToggleAll(name) {
      // console.log(this.myObj);
      this.myObj.forEach((groups) => {
        groups.children.forEach((forms) => {
          if (name == "view") forms.view = this.all.view;
          else if (name == "add") forms.add = this.all.add;
          else if (name == "edit") forms.edit = this.all.edit;
          else if (name == "delete") forms.delete = this.all.delete;
        });
      });
    },
    async openModal() {
      this.all = {
        view: false,
        add: false,
        edit: false,
        delete: false,
      };
      this.request = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Forms/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log(this.myObj);

      // this.myObj.forEach((groups) => {
      //   groups.children.forEach((forms) => {
      //     let obj = this.permList.find((el) => el.title == forms.title);
      //     if (obj) {
      //       forms.permission = obj.data.map((el) => ({
      //         text: el.text,
      //         value: el.value,
      //       }));
      //       // console.log(forms);
      //     }
      //   });
      // });

      this.roleObj = {
        id: 0,
        name: "",
        forms: "",
        campusID: this.$store.state.userData.cId,
      };
      this.$bvModal.show("modal-roles");
      this.request = false;
    },
    async openEdit(role) {
      this.checkId = role.id;
      this.editLoading = true;
      this.all = {
        view: false,
        add: false,
        edit: false,
        delete: false,
      };
      var obj = {
        url:
          this.$store.state.domain +
          "Forms/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let temp = await this.get(obj);
      this.roleObj = role;
      // this.myObj = JSON.parse(this.roleObj.forms);
      let currentFields = JSON.parse(this.roleObj.forms);
      // console.log(currentFields);

      temp.forEach((el) => {
        currentFields.forEach((curr) => {
          // comparing menu name
          if (el.name == curr.name) {
            el.children.forEach((el_ch) => {
              curr.children.forEach((curr_ch) => {
                // comparing submenu names
                if (el_ch.title == curr_ch.title) {
                  el_ch.view = curr_ch.view;
                  el_ch.add = curr_ch.add;
                  el_ch.edit = curr_ch.edit;
                  el_ch.delete = curr_ch.delete;
                  el_ch.hideFee = curr_ch.hideFee;
                  el_ch.editCampus = curr_ch.editCampus;
                  el_ch.interviewInfo = curr_ch.interviewInfo;
                  el_ch.approve = curr_ch.approve;
                  el_ch.disableDate = curr_ch.disableDate;
                  el_ch.receiveFee = curr_ch.receiveFee;
                  el_ch.disableImport = curr_ch.disableImport;
                  el_ch.disableExport = curr_ch.disableExport;
                  el_ch.reportTypes = curr_ch.reportTypes
                    ? curr_ch.reportTypes
                    : [];
                  el_ch.permission = curr_ch.permission;
                }
              });
            });
          }
        });
      });
      // console.log(temp);
      this.myObj = temp;
      this.editLoading = false;
      this.$bvModal.show("modal-roles");
    },

    AddOpen() {
      this.userObj = {
        id: 0,
        username: "",
        password: "",
        userRole: 0,
        name: "",
        avatar: "",
        forms: "",
        meta_role: "",
        campusID: this.$store.state.userData.cId,
      };
      this.visibility = true;
      this.sidebarTitle = "Add User";
      this.sidebarButton = "Save";

      this.selectedRole = null;
      this.logoloading = false;
      this.plus = "";
      var elem = this.$refs["UName"];
      elem.state = undefined;
      var elem = this.$refs["nameUser"];
      elem.state = undefined;
      var elem = this.$refs["role"];
      elem.state = undefined;
      var elem = this.$refs["pass"];
      elem.state = undefined;
    },
    Edit(item) {
      this.userObj = { ...item };
      this.selectedRole = item.meta_role;
      if (this.userObj.avatar !== null && this.userObj.avatar !== "") {
        this.plus = this.userObj.avatar;
        this.logoloading = "loaded";
      } else {
        this.logoloading = false;
      }
      // console.log(this.userObj);
      this.visibility = true;
      this.sidebarTitle = "Update User";
      this.sidebarButton = "Update";

      var elem = this.$refs["UName"];
      elem.state = undefined;
      var elem = this.$refs["nameUser"];
      elem.state = undefined;
      var elem = this.$refs["role"];
      elem.state = undefined;
      var elem = this.$refs["pass"];
      elem.state = undefined;
    },
    setRoleData() {
      // console.log(this.selectedRole);
      this.userObj.userRole = this.selectedRole.id;
      // this.userObj.forms = this.selectedRole.forms;
      let parsedObj = JSON.parse(this.selectedRole.forms);
      let newObj = [];
      parsedObj.forEach((menu) => {
        let newChildren = [];
        menu.children.forEach((el) => {
          if (el.view || el.add || el.edit || el.delete || el.permission)
            newChildren.push(el);
        });
        if (newChildren.length > 0) {
          menu.children = newChildren;
          newObj.push(menu);
        }
      });
      // console.log(newObj);
      this.userObj.forms = JSON.stringify(newObj);
      // console.log(this.userObj);
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      // console.log(this.fileProfile);
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        var axios = require("axios");
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.appick.io", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.plus = fn1;
            this.userObj.avatar = this.plus;
            // console.log(this.userObj.avatar);
            this.logoloading = "loaded";
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.avatar = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.roleObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckUName() {
      var elem = this.$refs["nameUser"];
      if (this.userObj.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckUserName() {
      var elem = this.$refs["UName"];
      if (this.userObj.username == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckPassword() {
      var elem = this.$refs["pass"];
      if (this.userObj.password == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckRole() {
      var elem = this.$refs["role"];
      if (this.userObj.userRole == 0 || this.userObj.forms == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async Add() {
      this.CheckUName();
      this.CheckUserName();
      this.CheckPassword();
      this.CheckRole();
      if (
        this.CheckUName() == false ||
        this.CheckUserName() == false ||
        this.CheckPassword() == false ||
        this.CheckRole() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.loading = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Users/Save?db=" +
            this.$store.state.userData.db,
          body: this.userObj,
          message: "User added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadUsers();
          this.visibility = false;
        }
        this.loading = false;
      }
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.put({
          url:
            this.$store.state.domain +
            "Users/RemoveUser?_db=" +
            this.$store.state.userData.db +
            "&id=" +
            id,
          message: "User removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadUsers();
      }
    },
    async save() {
      // console.log(this.roleObj);
      this.CheckName();
      if (this.CheckName() == false) {
        return this.$bvToast.toast("Please enter the role.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.roleObj.forms = JSON.stringify(this.myObj);
        // console.log(this.roleObj.forms);
        if (this.roleObj.id == 0) {
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "Roles?db=" +
              this.$store.state.userData.db,
            body: this.roleObj,
            message: "Role added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadData();
            this.$bvModal.hide("modal-roles");
          }
        } else {
          this.request = true;
          var newTabs = [];
          var newForms = [];
          var fData = JSON.parse(this.roleObj.forms);
          // console.log("#####fDATA", fData);
          fData.forEach((item) => {
            item.children.forEach((el) => {
              if (el.route != null) el.route = el.route.replace("\n", "");
              if (el.view || el.add || el.edit || el.delete || el.permission)
                newForms.push(el);
            });
            item.children = newForms;
            newForms = [];

            if (item.children.length > 0) newTabs.push(item);
          });

          this.roleObj.forms = JSON.stringify(newTabs);

          var status = await this.post({
            url:
              this.$store.state.domain +
              "Roles/EditRole?db=" +
              this.$store.state.userData.db,
            body: this.roleObj,
            message: "Role updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadData();
            this.$bvModal.hide("modal-roles");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.scrollContent {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
